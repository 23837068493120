import { useRef, useState } from "react"
import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import { SecretPopover } from "src/components/ApiClients/ApiClientsTable"
import ExpandDownIcon from "src/ui/icons/expand-down.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ParadiseUserApiClientSecret({
  clientId,
}: {
  clientId: string
}) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  return (
    <div>
      <TextBox>
        <MText variant="body">••••••••••••••</MText>
        <IconButton
          ref={anchorRef}
          onClick={(e) => setOpen((prev) => !prev)}
          size="small"
        >
          <ExpandDownIcon width={10} />
        </IconButton>
      </TextBox>

      {open && (
        <SecretPopover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          onClose={() => setOpen(false)}
          clientId={clientId}
        />
      )}
    </div>
  )
}

const TextBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS2};
`
